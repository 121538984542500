<template>
  <p>organizations</p>
</template>

<script>
export default {
  name: "OrganizationsList",
  mounted() {
    // Redirect organization member to it's own organization
    const orgId = this.$auth.user()?.organization?.id;
    if (orgId) {
      this.$router.replace({
        name: "organization-show",
        params: { id: orgId },
      });
    }
  },
};
</script>

<style>
</style>